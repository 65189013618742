@tailwind base;
@tailwind components;
@tailwind utilities;




/* *{
    margin: 0;
    padding: 0;
} */


/* global.css*/

 

/* ::-webkit-scrollbar {
    width: 7px;
    height: 100%;
}
 

::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
}
 

::-webkit-scrollbar-thumb {
    background: #b4b4b4;
    border-radius: 5px;
}
 

::-webkit-scrollbar-thumb:hover {
    background: #888888;
} */

.draggable-container {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    position: relative;
    z-index: 1; 
    cursor: grab!important;
 
  }

  .custom-phone-input input {
    border-color: white !important; /* Set the border color to white or any other desired color */
    outline: none !important; /* Remove the default focus outline */
    color: #000;
    width: 500px !important;
    
  }
  
  .custom-phone-input input:focus {
    border-color: white !important; /* Set the border color to white or any other desired color */
    outline: none !important; /* Remove the focus outline on focus */
  }

 /* Container styling */
.Toastify__toast-container {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    padding: 0 !important; /* Ensure no padding around the container */
  }
  
  /* Toast styling */
  .Toastify__toast {
    display: inline-block !important;
    max-width: 90% !important; /* Adjust max-width to 90% for better responsiveness */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
  
  /* Media query for small devices */
  @media (max-width: 600px) {
    .Toastify__toast {
        max-width: 95% !important; /* Increase max-width for smaller screens */
        white-space: normal !important; /* Allow wrapping for small screens */
        word-wrap: break-word !important; /* Break long words if necessary */
    }
  }
  
  /* Media query for medium devices */
  @media (min-width: 601px) and (max-width: 1024px) {
    .Toastify__toast {
        max-width: 90% !important; /* Adjust max-width for medium screens */
        white-space: normal !important; /* Allow wrapping for medium screens */
        word-wrap: break-word !important; /* Break long words if necessary */
    }
  }
  
  /* Media query for large devices */
  @media (min-width: 1025px) {
    .Toastify__toast {
        max-width: 80% !important; /* Set max-width for larger screens */
    }
  }

  .dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .dropzone p {
    margin: 0;
    font-size: 16px;
    color: #666666;
  }
  