.scroll-test {
    width: 7px;
    height: 100%;
}

.scroll-test {
    background: #fff;
    border-radius: 10px;
}
 

.scroll-test {
    background: #b4b4b4;
    border-radius: 5px;
}
 

/* ::-webkit-scrollbar-thumb:hover {
    background: #888888;
} */