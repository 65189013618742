.loading-overlay {
    position: absolute;
    top: 0;
    width: 80%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }